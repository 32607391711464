<template>
  <div>
    <back-nav to="repairParts" />
    <div class="tw-my-2 tw-flex tw-flex-row tw-justify-between">
      <h2 class="tw-text-xl c-gray-text">Damaged Vehicle Photos</h2>
      <label
        class="tw-rounded tw-flex tw-flex-row primary tw-h-10 tw-cursor-pointer"
      >
        <v-icon color="white" class="tw-mx-2">mdi-upload</v-icon>
        <p class="tw-mt-2 tw-mr-2 tw-text-white">Batch Upload</p>
        <input
          id="batch"
          class="tw-hidden"
          type="file"
          multiple
          @change="batchImageUpload"
          accept="image/*"
        />
      </label>
    </div>
    <hr />
    <div class="tw-py-2 md:tw-py-4">
      <p>
        Attach and submit the photos of the damaged vehicle to show where
        necessary repairs should be done.
      </p>
      <small class="tw-w-full c-yellow-text"
        >maximum of 5 MB for each image</small
      >
    </div>
    <v-form>
      <div v-if="uploaded">
        <file-upload
          v-model="images[0]"
          :added-image="images[0]"
          :index="0"
          name="photo-1"
          @delete-image="deleteImage(0)"
          @add-comment="addComment"
        />
      </div>
      <div
        v-else
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-2 md: tw-gap-10"
      >
        <div v-for="(n, i) in pics" :key="`${images}-${i}`">
          <span v-if="pics > 0"
            >Photo {{ n }}
            <sup v-if="n <= 3" class="tw-text-red-500">*</sup></span
          >
          <file-upload
            v-model="images[i]"
            :added-image="images[i]"
            :index="i"
            name="photo-1"
            @delete-image="deleteImage(i)"
            @add-comment="addComment"
          />
        </div>
      </div>
      <div class="tw-flex tw-justify-center">
        <div
          class="
            tw-w-2/3
            md:tw-w-1/3
            tw-py-4
            md:tw-py-10
            tw-space-x-2 tw-flex tw-justify-center
          "
        >
          <v-btn
            color="primary"
            outlined
            @click="() => {
              this.removeContributionText()
              $root.$emit('nav-back-to', { currentTab: 'repairParts' })
            }"
            >Previous</v-btn
          >
          <v-btn color="primary" :disabled="!isValid" @click="proceedNext"
            >Next</v-btn
          >
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import BackNav from '@/components/BackNav'
import FileUpload from '@/components/FileUpload'
import Validations from '@/utils/Validations'
import biddingFormMixin from '@/mixins/biddingFormMixin'

export default {
  name: 'vehiclePhotos',
  props: ['booking'],
  components: { FileUpload, BackNav },
  data() {
    return {
      ...Validations,
      images: [],
      pics: 0,
      uploaded: true,
      imageArray: [],
    }
  },
  mixins: [biddingFormMixin],
  watch: {
    images() {
      const length = this.images.length
      if (length === 0) {
        this.uploaded = true
      } else {
        this.addImages(this.images)
      }
    },
  },
  computed: {
    isValid() {
      return this.images.length >= 3
    },
  },
  methods: {
    deleteImage(index) {
      setTimeout(() => {
        this.images = this.images.filter((_, i) => i !== index)
      }, 1000)
    },
    proceedNext() {
      this.$store.commit('bidForm/updateBiddingForm', {
        photos: Object.values(this.images).filter(
          (value) => Object.keys(value).length !== 0
        ),
      })
      this.$emit('to-special-notes')
    },
    addImages(val) {
      const valueLength = val.length
      this.uploaded = false
      if (valueLength < 20) {
        this.pics = valueLength + 1
      } else if (valueLength === 20) {
        this.pics = valueLength
      } else {
        this.$store.commit('SNACKBAR', {
          state: 'failed',
          message: 'Maximum 20 images reached',
          variant: 'success',
        })
      }
    },
    addComment(comment, index, selectedImage) {
      this.$set(this.images, index, { ...selectedImage, imageComment: comment })
    },
    readAndPreview(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          () => {
            const image = {
              imageBase64: reader.result,
              imageName: file.name,
            }
            this.imageArray.push(image)
          },
          false
        )
        reader.readAsDataURL(file)
      }
    },
    batchImageUpload() {
      this.images = []
      const files = document.querySelector('input[type=file]').files
      let fileArray = [...files]
      fileArray = fileArray.filter((_, i) => i < 20)
      fileArray.forEach((file, index) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          const FileSize = file.size / 1024 / 1024
          if (FileSize > 5) {
            fileArray.filter((_, i) => i !== index)
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: ` Missing Image(s) are greater than 5 MB`,
              variant: 'error',
            })
          } else if (FileSize < 5) {
            this.readAndPreview(file)
          } else {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: 'Missing Image(s) are not valid',
              variant: 'error',
            })
          }
          setTimeout(() => {
            this.images = [...this.imageArray]
          }, 3000)
          this.imageArray = []
        }
      })
    },
  },
}
</script>
